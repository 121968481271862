import React from 'react';

import TechTemplate from '../technologies-template/technologies-template';
import Styles from './technologies-container.module.scss';

export default ( props ) => (

  <section className={Styles.tech}>
    <h2 className={Styles.sectionTitle}>Technologies we use</h2>
    <div className="container">
      <p className={Styles.text}>
        Here at Radium Digital Agency, we use a whole toolbox of technologies 
        that we understand well and use regularly for our clients. 
      </p>
    </div>
    <div className={Styles.techContainer}>
      {Object.entries(props).map( ( [key, value] ) => 
        <TechTemplate key={key} {...value}/>
      )}
    </div>
  </section>
);
