import React from 'react';

import './watermark-styles.scss';

const White = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 634 845">
    <path 
      data-name="Shape 2 copy 2" 
      className="mark--white" 
      d="M-247,2873h128.31L70,2231.705,258.69,2873H387L130.381,2028H9.619Z" transform="translate(247 -2027.999)"
    />
  </svg>
);

const Green = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 253 337.393">
    <path 
      data-name="Shape 2" className="mark--green" 
      d="M1461,938.393h51.2l75.3-256.057,75.3,256.057H1714L1611.6,601H1563.4Z" transform="translate(-1461 -601)"
    />
  </svg>
);

export { White, Green };