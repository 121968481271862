import React from 'react';
import Swiper from 'react-id-swiper';
import { Helmet } from 'react-helmet';
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";

import Testimonial from '../testimonial-template/testimonial-template';
import Styles from './testimonials-container.module.scss';

export default ( props ) => {

  const params = {
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    renderPrevButton: () => (
    
      <button 
        className="swiper-button-prev"
        aria-label="Previous"
      >
        <FaChevronLeft />
      </button>
    ),
    renderNextButton: () => (

      <button 
        className="swiper-button-next"
        aria-label="Next"
      >
        <FaChevronRight />
      </button>
    )
  };


  return(
    <section>
      <Helmet>
        <link 
          rel="stylesheet" 
          href=
            "https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.0/css/swiper.min.css"
        />
      </Helmet>
      <h2 className={Styles.sectionTitle}>Testimonials</h2>
      <div className={Styles.testimonialsContainer}>
        <Swiper {...params}>
          {Object.entries(props).map( ([ key, value ]) => 
            <div key={key}>
              <Testimonial {...value}/>
            </div>
          )}
        </Swiper>
      </div>
    </section>
  )
  
};