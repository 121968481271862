import React from 'react';
import { Brain, Rocket, Bottle } from '../service-icons/service-icons';
import { White } from '../watermark/watermark';
import { FaCircle } from "react-icons/fa";

import Styles from './services-component.module.scss';

export default () => (
  
  <section className={Styles.services}>

    <div className={Styles.mark}>
      <White />
    </div>

    <div className="container">
      <h2 className={Styles.sectionTitle}>
        Digital Solutions That Work
      </h2>
      <div className={Styles.mainContainer}>
        <div className={Styles.brainContainer}>
          <div className={Styles.mainIcon}>
            <Brain />
          </div>
          <h4 className={Styles.title}>Strategy &amp; Data Analysis</h4>
          <p className={Styles.text}>
            The marketing world is shifting from “tell me” to “show me.” 
            Start-ups who show metrics and work with them win every time. 
            At Radium Digital Agency, our strategy is to help you get more 
            exposure and impact through:
          </p>
          <ul>
            <li className={Styles.line}>
              <FaCircle />User Insights
            </li>
            <li className={Styles.line}>
              <FaCircle />Analysing Data
            </li>
            <li className={Styles.line}>
              <FaCircle />Data strategy and reporting
            </li>
          </ul>
        </div>
        <div className={Styles.rocketContainer}>
          <div className={Styles.mainIcon}>
            <Rocket />
          </div>
          <h4 className={Styles.title}>User Experience &amp; creative</h4>
          <p className={Styles.text}>
            As user experience took centre stage in product developments, 
            Radium Digital Agency saw increased focus from brands to 
            create experiences that boost usability and result in:
          </p>
          <ul>
            <li className={Styles.line}>
              <FaCircle />Concept design &amp; validation
            </li>
            <li className={Styles.line}>
              <FaCircle />User Journey &amp; content audit
            </li>
            <li className={Styles.line}>
              <FaCircle />Information architecture
            </li>
            <li className={Styles.line}>
              <FaCircle />Brand Identity
            </li>
          </ul>
        </div>
        <div className={Styles.bottleContainer}>
          <div className={Styles.mainIcon}>
            <Bottle />
          </div>
          <h4 className={Styles.title}>Technology &amp; platform</h4>
          <p className={Styles.text}>
            Whether you realize it or not, omni-channels marketing is all around you. Radium Agency build and automate immersive web experiences through digital omni-channels that put the customer first. 
          </p>
          <ul>
            <li className={Styles.line}>
              <FaCircle />Full-stack technical development
            </li>
            <li className={Styles.line}>
              <FaCircle />Mobile and Tablet Apps design &amp; development
            </li>
            <li className={Styles.line}>
              <FaCircle />Optimisation and testing
            </li>
          </ul>
        </div>
      </div>

    </div>
  </section>
);