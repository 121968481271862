import React from 'react';

import Styles from './testimonial-template.module.scss';

export default ({ mainImg, name, position, text }) => (
  
  <div className={Styles.testimonialContent}>
    <div className={Styles.avatar}>
      {mainImg}
    </div>
    <div className={Styles.titleContainer}>
      <h4 className={Styles.name}>
        { name }
      </h4>
      <p className={Styles.position}>
        { position }
      </p>
    </div>
    <p className={Styles.text}>
        { text }
    </p> 
  </div>
);
