import React from 'react';

/**
 * Images/SVG
 */
import Background from '../images/bannerBackground.png';
import WordpressLogo from '../images/wordpress-logo.png';
import CssLogo from '../images/css.png';
import PhpLogo from '../images/php.png';
import ReactLogo from '../images/react.png';
import HtmlLogo from '../images/html.png';
import JsLogo from '../images/js.png';
import AvatarImg from '../images/avatar.jpeg';
import { CanonLogo } from '../components/canon-logo/canon-logo';
import JllLogo from '../images/jll_logo.png';
import UsiLogo from '../images/usi-logo.png';
import CanonPro from '../images/pro.png';
import CanonCPS from '../images/cps.png';
import Jll from '../images/jll1.png';
import Usimoney from '../images/usi.png';

/**
 * Components
 */
import SEO from '../components/seo/seo';
import Layout from '../components/layout/layout';
import Banner from '../components/banner/banner';
import OverlapElement from 
  '../components/banner-overlap-element/banner-overlap-element'
import Services from '../components/services-component/services-component';
import ClientsContainer from 
  '../components/clients-container/clients-container';
import Technologies from 
  '../components/technologies-container/technologies-container';
import Testimonials from 
  '../components/testimonials-container/testimonials-container';



export default () => {

  /**
   * Client data block
   */
  const clientsData = {

    home: true,
    hidden: [
      {
        mainImg: <img src={Jll} alt="jll main"/>,
        logo: <img src={JllLogo} alt="jll logo"/>,
        link: 'jll',
        subTitle: 'About JLL',
        text: 
          'We are the company responsible for the property management of The Crown Estate’s central London holding, including its Regent Street portfolio.',
        companyLink: 'https://www.myregentstreet.com/'
      },
      {
        mainImg:  <img src={CanonCPS} alt="cps main"/>,
        logo: <CanonLogo />,
        link: 'cps',
        subTitle: 'About Canon',
        text: 
          'Canon professional services provide canon customers the opportunity to tell their exciting stories better and become full time imaging professionals with priority support. These include exclusive phone and email support, equipment loans and repairs, on-site support, discounts for events and special offers.',
        companyLink: 'https://www.canon-europe.com/pro/canon-professional-services/'
      }
    ],
    view: [
      {
        mainImg: <img alt="pro" src={CanonPro}/>,
        logo: <CanonLogo />,
        link: 'pro',
        subTitle: 'About Canon',
        text: 
          'Canon professional services provide canon customers the opportunity to tell their exciting stories better and become full time imaging professionals with priority support. These include exclusive phone and email support, equipment loans and repairs, on-site support, discounts for events and special offers.',
        companyLink: 'https://www.canon-europe.com/pro'
      },
      {
        mainImg:  <img src={Usimoney} alt="usimoney main"/>,
        logo: <img src={UsiLogo} alt="usi logo"/>,
        link: 'usimoney',
        subTitle: 'About USI Money',
        text: 
          'USI Money is a unique foreign exchange provider offering bespoke solutions to both individual and corporate clients. USI Money is the ideal partner to trade your FX with. They hold the one of the biggest Foreign Exchange markets in the World.',
        companyLink: 'https://www.usimoney.com/'
      },
    ]
  };

  /**
   * Tech data block
   */
  const techData = {

    php: {
      image: <img src={PhpLogo} alt="php"/>
    },
    react: {
      image: <img src={ReactLogo} alt="react"/>
    },
    wordpress: {
      image: <img src={WordpressLogo} alt="wordpress"/>
    },
    css: {
      image: <img src={CssLogo} alt="css"/>
    },
    js: {
      image: <img src={JsLogo} alt="js.png"/>
    },
    html: {
      image: <img src={HtmlLogo} alt="html"/>
    }
  };

  /**
   * Testimonials data block
   */
  const testimonialsData = [

    {
      mainImg: <img src={AvatarImg} alt="avatar"/>,
      name: 'Maria Flouti',
      position: '',
      text: 
        'The website was launched successfully and went live at the start of 2019.  Alongside the celebrations held in our head office, Radium Agency also popped a bottle of champagne in its office nearby to toast a job well-done.',
    },
    {
      mainImg: <img src={AvatarImg} alt="avatar"/>,
      name: 'Khaleeq Taimuri',
      position: '',
      text: 
        'Excellent work on the usimoney.com website and Mobile app.',
    },
    {
      mainImg: <img src={AvatarImg} alt="avatar"/>,
      name: 'Paul Bone',
      position: '',
      text: 
        'I am working with Radium agency since many years. Great work on the Website firstcollect international and SEO.',
    },
    {
      mainImg: <img src={AvatarImg} alt="avatar"/>,
      name: 'William McGown',
      position: '',
      text: 
        'Great work guys keep it up ',
    }
  ];
  
  return (
    <Layout>
      <SEO 
        title="Home" 
        keywords={[ `digital`, `agency`, `technology`, `Radium` ]} 
      />
      <Banner background={{ backgroundImage: `url(${Background}` }} />
      <OverlapElement />
      <ClientsContainer {...clientsData}/>
      <Services />
      <Technologies {...techData}/>
      <Testimonials {...testimonialsData}/>
    </Layout>
  )
};
