import React from 'react';

import Styles from './technologies-template.module.scss';

export default ({ image }) => (
  <div className={Styles.techImgOuterContainer}>
    <div className={Styles.techImginnerContainer}>
      {image}
    </div>
  </div>
);
